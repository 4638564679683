import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import range from "range"
import windowSize from "react-window-size"

import { Button, ButtonGroup } from "../../components/core/button"
import Column from "./section-column"
import Layout from "../../layouts"
import Container from "../../components/container"
import FullWidth from "./section-full-width"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Title from "../../components/hero/title"
import Subtitle from "../../components/hero/subtitle"
import Background1 from "../../assets/why-gatsby-bg-block-1.svg"
import Background2 from "../../assets/why-gatsby-bg-block-2.svg"
import PullBelowHeader from "../../components/containers/pull-below-header"
import HeroContainer from "../../components/containers/hero"

import { mediaQueries, space } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import { getLayout } from "../../utils/layout"

const sectionBackgrounds = [Background1, Background2]

class WhyGatsbyPage extends React.Component {
  render() {
    const { contentfulPage, nav } = this.props.data
    const { sections, title, subtitle } = contentfulPage
    const clientHeight = this.props.windowHeight || 1

    return (
      <Layout
        pathname={this.props.location.pathname}
        background={getLayout(this.props.location.pathname).background}
      >
        <SEO contentfulPage={contentfulPage} />
        <Header navItems={nav.edges} isInverted />
        <PullBelowHeader>
          <HeroContainer isInverted>
            <Container customStyles={{ zIndex: 10, position: `relative` }}>
              <Title isInverted customStyles={{ maxWidth: rhythm(20) }}>
                {title}
              </Title>
              <Subtitle isInverted customStyles={{ maxWidth: rhythm(28) }}>
                {subtitle.subtitle}
              </Subtitle>
              <div
                css={{
                  marginBottom: space[13],
                  [mediaQueries.tablet]: {
                    display: `flex`,
                    marginLeft: `-${space[12]}`,
                    marginRight: `-${space[12]}`,
                  },
                }}
              >
                {sections[0].featuresList.map((section, i) => (
                  <Column section={section} index={i} key={i} />
                ))}
              </div>
            </Container>
            {range.range(0, sections[1].featuresList.length / 2).map(i => (
              <div
                css={{
                  paddingBottom: i >= 1 ? 10 : false,
                  position: `relative`,
                }}
                key={i}
              >
                <div
                  css={{
                    [mediaQueries.tablet]: {
                      background: `url(${
                        sectionBackgrounds[i]
                      }) 50% 0 no-repeat`,
                      backgroundSize: `cover`,
                      bottom: 0,
                      position: `absolute`,
                      left: 0,
                      right: 0,
                      top: i === 0 ? -80 : 0,
                    },
                  }}
                  key={i}
                />
                <Container>
                  <FullWidth
                    section={sections[1].featuresList[2 * i]}
                    index={i >= 1 ? i + 1 : i}
                    clientHeight={clientHeight}
                  />
                </Container>
                {sections[1].featuresList.length > 2 * i + 1 ? (
                  <Container>
                    <FullWidth
                      section={sections[1].featuresList[2 * i + 1]}
                      index={i >= 1 ? i + 2 : i + 1}
                      clientHeight={clientHeight}
                    />
                  </Container>
                ) : null}
              </div>
            ))}
            <Container
              customStyles={{
                textAlign: `center`,
                paddingBottom: rhythm(6),
              }}
            >
              <ButtonGroup>
                <Button to="/how-it-works" buttonStyle="secondary">
                  How It Works
                </Button>
                <Button to="/contact-us/">Contact Us</Button>
              </ButtonGroup>
            </Container>
          </HeroContainer>
        </PullBelowHeader>
        <Footer navItems={nav.edges} isInverted />
      </Layout>
    )
  }
}

WhyGatsbyPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  windowHeight: PropTypes.number,
}

export default windowSize(WhyGatsbyPage)

export const pageQuery = graphql`
  query ContentfulWhyGatsbyPage($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      keyvisual {
        name
      }
      ...SocialMediaImage
      sections {
        title
        linkText
        associatedPage {
          name
          slug
          parentPage {
            name
            slug
          }
        }
        layout
        text {
          childMarkdownRemark {
            html
          }
        }
        keyvisual {
          name
        }
        ...FeaturesListFragment
      }
    }
  }
`
