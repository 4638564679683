import React from "react"
import propTypes from "prop-types"
import Plx from "react-plx"
import kebabCase from "lodash/kebabCase"

import HeadlineSmallCaps from "../../components/headline-small-caps"
import {
  fonts,
  fontSizes,
  lineHeights,
  mediaQueries,
  space,
  colors,
} from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import LazySVGImg from "../../components/lazy-svg"

class FullWidthSection extends React.Component {
  render() {
    const { section, index, clientHeight } = this.props
    const Keyvisual = section.keyvisual && section.keyvisual.name
    const isOdd = (index + 1) % 2 === 0

    return (
      <div
        css={{
          marginBottom: rhythm(6),
          [mediaQueries.phablet]: {
            alignItems: `center`,
            display: `flex`,
            marginBottom: index > 0 ? rhythm(10) : space[9],
          },
        }}
      >
        {Keyvisual && (
          <div
            css={{
              marginLeft: `auto`,
              marginRight: `auto`,
              textAlign: `center`,
              maxWidth: `50%`,
              [mediaQueries.phablet]: {
                order: isOdd ? 0 : 1,
                marginRight: isOdd ? `auto` : false,
                marginLeft: isOdd ? false : `auto`,
                paddingRight: isOdd ? space[12] : false,
                paddingLeft: isOdd ? false : space[12],
              },
            }}
          >
            <Plx
              animateWhenNotInViewport
              parallaxData={[
                {
                  duration: clientHeight,
                  offset: 0,
                  properties: [
                    {
                      endValue: -40,
                      property: `translateY`,
                      startValue: 40,
                    },
                  ],
                  start: `self`,
                },
              ]}
            >
              <LazySVGImg
                src={`${Keyvisual}.svg`}
                alt={``}
                css={{
                  transform: `translateZ(0)`,
                }}
              />
            </Plx>
          </div>
        )}
        <div
          css={{
            WebkitFontSmoothing: `antialiased`,
            [mediaQueries.phablet]: {
              marginTop: -80,
              width: rhythm(26),
            },
          }}
        >
          <HeadlineSmallCaps
            withHr={index === 0}
            divId={kebabCase(section.title)}
          >
            {section.title}
          </HeadlineSmallCaps>
          <div
            css={{
              "& p": {
                color: colors.white,
                fontFamily: fonts.header,
                fontSize: fontSizes[5],
                lineHeight: lineHeights.dense,
                [mediaQueries.desktop]: {
                  fontSize: fontSizes[6],
                },
                [mediaQueries.hd]: {
                  fontSize: fontSizes[7],
                },
              },
            }}
            dangerouslySetInnerHTML={{
              __html: section.text && section.text.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    )
  }
}

FullWidthSection.propTypes = {
  section: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  clientHeight: propTypes.number,
}

export default FullWidthSection
