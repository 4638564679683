import React from "react"
import PropTypes from "prop-types"

import { mediaQueries, space, colors } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import LazySVGImg from "../../components/lazy-svg"

const Columns = ({ section }) => {
  const Keyvisual = section.keyvisual && section.keyvisual.name

  return (
    <div
      css={{
        [mediaQueries.tablet]: {
          display: `flex`,
          flexDirection: `column`,
          flexGrow: 0,
          flexShrink: 0,
          width: `50%`,
          padding: `0 ${space[12]}`,
        },
      }}
    >
      <div
        css={{
          display: `flex`,
          alignItems: `flex-end`,
          [mediaQueries.tablet]: {
            minHeight: 240,
          },
        }}
      >
        <h2
          css={{
            color: colors.white,
            display: `inline-block`,
            flexGrow: 1,
            marginBottom: space[6],
            marginRight: `auto`,
            marginTop: space[9],
            paddingRight: space[9],
            WebkitFontSmoothing: `antialiased`,
            [mediaQueries.phablet]: {
              maxWidth: rhythm(16),
            },
          }}
        >
          {section.title}
        </h2>
        {Keyvisual && (
          <LazySVGImg
            src={`${Keyvisual}.svg`}
            alt={``}
            css={{
              flexShrink: 1,
              minWidth: 80,
            }}
          />
        )}
      </div>
      <div
        css={{
          "& p": {
            color: colors.purple[`300`],
            maxWidth: rhythm(22),
            WebkitFontSmoothing: `antialiased`,
          },
        }}
        dangerouslySetInnerHTML={{
          __html: section.text && section.text.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

Columns.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default Columns
