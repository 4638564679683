import React from "react"
import PropTypes from "prop-types"

import {
  colors,
  fontSizes,
  letterSpacings,
  lineHeights,
  space,
} from "../utils/presets"
import { smallCapsStyles } from "../utils/styles"

const HeadlineSmallCaps = ({
  children,
  withHr,
  color = colors.purple[`300`],
  hrColor = `${colors.gatsby}6f`,
  divId,
}) => {
  const hr = withHr
    ? {
        background: hrColor,
        content: `" "`,
        height: 1,
        position: `absolute`,
        left: 0,
        right: 0,
        top: `50%`,
      }
    : false

  return (
    <h3
      css={{
        ...smallCapsStyles,
        fontSize: fontSizes[2],
        color: color,
        position: `relative`,
        lineHeight: lineHeights.loose,
        letterSpacing: letterSpacings.mega,
        fontWeight: `normal`,
        paddingRight: space[9],
        marginBottom: space[9],
        display: `flex`,
      }}
      id={divId}
    >
      <span
        css={{
          display: `inline-block`,
          flex: `0 0 auto`,
          position: `relative`,
          paddingRight: space[9],
          zIndex: 1,
          "&:after": {
            background: colors.gatsby,
            content: `" "`,
            height: 1,
            position: `absolute`,
            right: 0,
            top: `50%`,
            width: space[8],
          },
        }}
      >
        {children}
      </span>
      <span
        css={{ flex: ` 1 1 100%`, position: `relative`, "&:after": { ...hr } }}
      />
    </h3>
  )
}

HeadlineSmallCaps.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  hrColor: PropTypes.string,
  withHr: PropTypes.bool,
  divId: PropTypes.string,
}

export default HeadlineSmallCaps
